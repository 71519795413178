
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rufina:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

@font-face {
  font-family: 'Breadley';
  src: url('../public/BreadleySans-Regular.ttf');
}


:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

.dark {
  @apply bg-zinc-50 text-zinc-50
}

.light {
  @apply bg-zinc-50 text-[#956d4f]
}

.pink-btn {
  @apply text-black px-6 py-3 rounded-xl font-bold bg-gradient-to-r from-[#C669C8] to-[#E689E8] hover:from-[#E689E8] hover:to-[#C669C8]
}

.pink {
  @apply text-[#E689E8]  
}

.learn {
  @apply bg-gradient-to-r from-[#F2A22B] p-3 rounded-xl hover:scale-105 cursor-pointer
}
